import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as CheckIcon } from '../../../../assets/images/purple-check.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/trash-red.svg';
import useDebounce from '../../../../helpers/useDebounceHook';
import { uppercase } from '../../../../helpers/utils';
import {
  clearAutomationActionData,
  getAutomationAction,
  getAutomationIntegrationAction,
  updateActionType,
} from '../../../../store/features/automationsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { AutomationLogActionWrapper } from '../../../../styles/components/automationDetails/automation-details.styled';
import InputSearch from '../../../common/input-search';
import UpdateAction from '../../../common/update-action';
import AutomationLogIntegartion from './automation-log-action-integration';
import AutomationSaveDetails from './automation-saved-details';

const SelectIntegration = props => {
  const {
    selectedIntegration,
    onSelectIntegration,
    automationLogActionIntegration,
    editAutomationActionData,
    editAutomationActionType,
    onCancel,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onUpdate = () => {
    const isIntegrationAvailable =
      selectedIntegration?.id && automationLogActionIntegration?.find(i => i.id === selectedIntegration?.id);
    if (!isIntegrationAvailable) {
      dispatch(addToast({ error: true, text: 'Please select integration', id: uuid() }));
      return;
    }
    onCancel();
  };

  return (
    <div className="px-6 pt-6 w-full">
      <label className="medium-text grey-text font-14">{t('SELECT_INTEGRATION')}</label>
      <div className="grid-trigger relative">
        {automationLogActionIntegration?.map(event => (
          <div
            className={`border radius-3 mt-4 pl-4 py-3 pr-5 flex flex-row gap-2 items-start cursor ${
              selectedIntegration?.id === event?.id ? 'selected' : ''
            }`}
            onClick={() => onSelectIntegration(event)}>
            <img src={event?.connector?.icon?.active} alt="icon" height={24} width={24} />
            <div className="flex flex-row w-full justify-between">
              <div className="flex-column">
                <label className="medium-text font-14">{event.name}</label>
                <label className="regular-text font-12 grey-text"> {event.description}</label>
              </div>
              {selectedIntegration?.id === event.id && (
                <div className="flex items-center">
                  <CheckIcon width={16} height={16} />
                </div>
              )}
            </div>
          </div>
        ))}
        {(editAutomationActionType === 'ACTION' || editAutomationActionType === 'INTEGRATION') && (
          <UpdateAction className="update-action" onCancel={onCancel} onUpdate={onUpdate} />
        )}
      </div>
    </div>
  );
};
const SelectActions = props => {
  const {
    automationLogAction,
    setSearchText,
    searchText,
    selected,
    onSelectTrigger,
    editAutomationActionData,
    editAutomationActionType,
    onCancel,
  } = props;
  return (
    <div className="flex-column items-start justify-start w-full pxy-6 relative">
      <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} className="input-search" />
      {automationLogAction?.length > 0 &&
        automationLogAction.map(item => (
          <div className="mt-8 w-full">
            <label className="medium-text grey-text font-14">{uppercase(item.name)}</label>
            <div className="grid-trigger">
              {item?.action_types.map(event => (
                <div
                  className={`border radius-3 mt-4 pl-4 py-3 pr-5 flex flex-row gap-2 items-start cursor ${
                    selected?.id === event.id ||
                    editAutomationActionData?.action_type?.action_type === event?.action_type
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => onSelectTrigger(event)}>
                  <img src={event?.icon?.active} alt="icon" height={24} width={24} />
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex-column">
                      <label className="medium-text font-14">{event.name}</label>
                      <label className="regular-text font-12 grey-text"> {event.description}</label>
                    </div>
                    {(selected?.id === event.id ||
                      editAutomationActionData?.action_type?.action_type === event?.action_type) && (
                      <div className="flex items-center">
                        <CheckIcon width={16} height={16} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      {editAutomationActionType === 'ACTION' && (
        <UpdateAction className="update-action" onCancel={onCancel} onUpdate={onCancel} />
      )}
    </div>
  );
};
const AddEditAutomationLogAction = ({
  length,
  editAutomationActionData = {},
  setEditAutomationActionData = () => {},
  editAutomationActionType = '',
}) => {
  const { automationLogAction, automationLogActionIntegration, automationLogIntegrationDetails } = useSelector(
    state => state.automations,
  );
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const [selected, setSelected] = useState(
    editAutomationActionType !== 'ACTION' ? editAutomationActionData?.action_type : {},
  );
  const [selectedIntegration, setSelectedIntegration] = useState(
    editAutomationActionData ? editAutomationActionData?.integration : null,
  );
  const [showSelectedIntegrationData, setShowSelectedIntegrationData] = useState(
    editAutomationActionData && editAutomationActionType !== 'ACTION_DATA' ? true : false,
  );
  const [showSaveIntegration, setSaveIntegration] = useState(false);
  const [filteredListData, setFilteredListData] = useState([]);

  const onSelectTrigger = selectedTrigger => {
    setSelected(selectedTrigger);
  };

  const onCancel = () => {
    if (editAutomationActionData?.id) {
      setEditAutomationActionData({});
    }
    dispatch(clearAutomationActionData());
    setSelected({});
    setSelectedIntegration({});
  };

  const onSelectIntegration = selectedItem => {
    setShowSelectedIntegrationData(false);
    setSelectedIntegration(selectedItem);
  };

  useEffect(() => {
    dispatch(getAutomationAction());
    setSaveIntegration(null);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearAutomationActionData());
      setSelected({});
      setSelectedIntegration({});
    };
  }, []);

  useEffect(() => {
    if (selectedIntegration?.id && !showSelectedIntegrationData) {
      dispatch(getAutomationIntegrationAction({ actionType: selected?.action_type, id: selectedIntegration?.id }));
    }
  }, [selectedIntegration?.id, showSelectedIntegrationData]);

  useEffect(() => {
    const filteredResults = automationLogAction
      ?.map(item => {
        const filteredActionTypes = item.action_types.filter(
          action => action.name.toLowerCase().includes(debouncedSearch.toLowerCase()) && action.action_type !== null,
        );

        return {
          ...item,
          action_types: filteredActionTypes,
        };
      })
      .filter(item => item.action_types.length > 0);
    setFilteredListData(filteredResults);
  }, [debouncedSearch, automationLogAction]);

  useEffect(() => {
    if (selected?.name) {
      dispatch(updateActionType(selected?.action_type))
        .then(response => {})
        .catch(error => {});
    }
  }, [selected]);

  const expandUI = (
    <div className="automation-action-wrapper flex-column border items-start justify-start w-full radius-3 mt-6 relative">
      <div className="flex-column items-start justify-start w-full pt-4 pb-6 border-bottom">
        <div className="flex flex-column w-full divider-bottom pr-6 pb-4">
          <div className="flex-row justify-between header-border-left ">
            <div className="flex items-center pl-5 flex-1">
              <div className="dot-2-green" />
              <label className="medium-text font-16 ml-2">{`Action ${length}`}</label>
            </div>
            <div className="flex items-center justify-start ">
              {editAutomationActionData?.id && (
                <div
                  className="flex items-center justify-center cursor edit-delete-btn-wrapper"
                  onClick={() => setEditAutomationActionData({})}>
                  <DeleteIcon />
                </div>
              )}
              {/* <ArrowDownIcon className={`flex items-center justify-center ml-6 cursor ${expand ? '' : 'rotate-180'}`} /> */}
            </div>
          </div>
          {(automationLogActionIntegration || selected?.action_type) && (
            <div className="flex justify-start sub-header-border radius-2 pl-6 pt-4 pb-2 flex-column">
              <div className={`border radius-3 pl-4 py-3 pr-5 flex flex-row gap-2`}>
                <img src={selected?.icon?.active} alt="" className="radius-4" height={24} width={24} />
                <div className="flex-column">
                  <label className="medium-text font-14">{selected?.name}</label>
                  <label className="font-12 grey-text"> {selected?.description}</label>
                </div>
              </div>
            </div>
          )}
        </div>
        {!automationLogActionIntegration && !automationLogIntegrationDetails && !showSaveIntegration && (
          <SelectActions
            automationLogAction={filteredListData}
            setSearchText={setSearchText}
            searchText={searchText}
            onSelectTrigger={onSelectTrigger}
            selected={selected}
            editAutomationActionData={editAutomationActionData}
            editAutomationActionType={editAutomationActionType}
            onCancel={onCancel}
          />
        )}
        {((automationLogActionIntegration && !automationLogIntegrationDetails && !showSaveIntegration) ||
          (automationLogActionIntegration &&
            automationLogIntegrationDetails &&
            !showSaveIntegration &&
            showSelectedIntegrationData)) && (
          <SelectIntegration
            selectedIntegration={selectedIntegration}
            onSelectIntegration={onSelectIntegration}
            automationLogActionIntegration={automationLogActionIntegration}
            editAutomationActionData={editAutomationActionData}
            editAutomationActionType={editAutomationActionType}
            onCancel={onCancel}
          />
        )}
        {automationLogIntegrationDetails && !showSaveIntegration && (
          <AutomationLogIntegartion
            selectedIntegration={selectedIntegration}
            selectActions={selected}
            onCancel={onCancel}
            setSaveIntegration={setSaveIntegration}
            stepNumber={length}
            editAutomationActionData={editAutomationActionData}
            setEditAutomationActionData={setEditAutomationActionData}
            editAutomationActionType={editAutomationActionType}
            showSelectedIntegrationData={showSelectedIntegrationData}
          />
        )}
        {showSaveIntegration && <AutomationSaveDetails integrationDetails={showSaveIntegration} />}
      </div>
    </div>
  );

  return <AutomationLogActionWrapper className="flex w-full"> {expandUI}</AutomationLogActionWrapper>;
};

export default AddEditAutomationLogAction;
